const script_element = document.createElement('script');
script_element.setAttribute(
  'src',
  'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
);
script_element.setAttribute('type', 'text/javascript');
script_element.setAttribute('charset', 'UTF-8');
script_element.setAttribute(
  'data-domain-script',
  '9ac302c6-5ad5-47d2-b22f-f8c2701898ef-test'
);

document.body.appendChild(script_element);
